<template>
  <div class="app-container">
    <!-- 顶部导航栏 -->
    <div class="top_title">
      <h5>密码修改</h5>

    </div>
    <!-- 顶部导航栏 -->

    <!-- 企业基本信息 -->
    <div class="filter-container">
      <div class="demo-input-suffix">
        <p>原密码:</p>
        <el-input v-model="temp.oldPassWord" placeholder="原密码" style="width: 200px;" class="filter-item-s" size="small"/>
      </div>
      <div class="demo-input-suffix">
        <p>新密码:</p>
        <el-input v-model="temp.passWord" placeholder="新密码" style="width: 200px;" class="filter-item-s" size="small"/>
      </div>
      <div class="demo-input-suffix">
        <p>确认密码:</p>
        <el-input v-model="temp.passWord2" placeholder="确认密码" style="width: 200px;" class="filter-item-s" size="small"/>
      </div>
        <el-button @click="savePassword()" type="primary" size="small" style="margin-top:20px;" plain>确认修改</el-button>
    </div>
    <!-- 企业基本信息 -->
    

  </div>
</template>

<script>
import { editPass } from '@/api/sys.js'
export default {
  name: 'secure-manage',
  data() {
    return {
      temp: {
        oldPassWord: '',
        passWord2: '',
        passWord: '',
      }
    }
  },
  created() {
  },
  methods: {
    savePassword(){
      if(this.temp.passWord2 != this.temp.passWord){
        this.$message.error('两次密码输入不一致');
        return
      }
      if(!this.temp.passWord){
        this.$message.error('请输入新密码');
        return
      }
      editPass(this.temp).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage("修改成功")
        }
      })

    }
  }
}
</script>

<style>
.demo-input-suffix .el-input__inner{
  border-radius: 0;
}
</style>
<style lang="scss" scoped>
.demo-input-suffix{
  text-align: justify;
  p{
    display: inline-block;
    width: 64px;
    font-size: 14px;
    line-height: 28px;
    text-align-last: justify;
    text-align: justify;
    text-justify: distribute-all-lines;
    margin-right: 6px;
  }
}
.top_title{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
  h5{
    font-size: 16px;
    color: $color-main;
    font-weight: bold;
    line-height: 36px;
  }
  
}
</style>
